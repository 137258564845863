import configureMeasurements from 'convert-units';
import current from 'convert-units/definitions/current';
import energy from 'convert-units/definitions/energy';
import power from 'convert-units/definitions/power';
import voltage from 'convert-units/definitions/voltage';
import volume from 'convert-units/definitions/volume';

const convert = configureMeasurements({
  current,
  energy,
  power,
  voltage,
  volume
});

export const getTotalOfDataAndUnit = (data, unit) => {
  let total = data.reduce((a, b) => a + b, 0)
  if (!total) return 0
  if (total > 1000000) {
    let value = convert(total).from(unit).toBest({ cutOffNumber: 1000 })
    let number = value.val.toLocaleString('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
    return `${number} [${value.unit.toUpperCase()}]`
  } else {
    let number = total.toLocaleString('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
    return `${number} [${unit.toUpperCase()}]`
  }
}
